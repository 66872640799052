import { HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/core/auth.service';
import { TokenStorage } from 'src/app/core/token-storage';
import { LoaderService } from 'src/app/service/loader.service';
import { RestService } from 'src/app/service/rest.service';
import { AlertDialogComponent } from '../component/Shared/alert-dialog/alert-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isEqual } from 'lodash-es';
@Component({
  selector: 'app-send-back-initiator-edit',
  templateUrl: './send-back-initiator-edit.component.html',
  styleUrls: ['./send-back-initiator-edit.component.css']
})
export class SendBackInitiatorEditComponent implements OnInit {
  transactionForm!: FormGroup;
  submitted: boolean = false;
  isCapex: boolean = false;
  isOpex: boolean = false;
  isOtherFinancial: boolean = false
  transactionDocument: Array<any> = [];
  files: File[] = [];
  id: string = '';
  transactionTypes: Array<any> = [];
  category: any;
  errorMsg = '';
  group: Array<any> = [];
  groupString: string = '';
  members: any;
  requiredDocument: string ='';
  requiredUrl: String ='';
  lowestEstimatedAmt: number = 0;
  highestEstimatedAmt: number = 0;
  constructor(
    public dialogRef: MatDialogRef<SendBackInitiatorEditComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder, 
    private authservice: AuthService, 
    private router: Router, 
    private fb: FormBuilder, 
    private loader: LoaderService, 
    public restService: RestService,
    private token: TokenStorage,
    private dialog: MatDialog,
    public MsalauthService: MsalService) { }

  ngOnInit(): void {
    this.transactionForm = this.formBuilder.group({
      type: ['', Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      capex: [''],
      opex: [''],
      OtherFinancial:[''],
      category:['']
     
    });

    this.transactionForm.get("capex")?.valueChanges.subscribe(data=>{
      this.betweenSelectedPriceEnterd();
    })
    this.transactionForm.get("opex")?.valueChanges.subscribe(data=>{
      this.betweenSelectedPriceEnterd();
    })
    this.transactionForm.get("OtherFinancial")?.valueChanges.subscribe(data=>{
      this.betweenSelectedPriceEnterd();
    })


    this.authservice.getMembers().subscribe(data => {
      this.members = data;
      
      this.getTransactionTypes();
      this.setValue()
    }, err => {
      if(err.status == 401) {
        const headers = new HttpHeaders({ 
          'Authorization' : 'Bearer ' + this.token.MSALget()
         });
         this.restService.postData(null,'/api/Account/GetApplicationToken', headers).subscribe((data) => {
           this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
           this.authservice.getMembers().subscribe(dataVal => {
            this.members = dataVal;
            this.getTransactionTypes();
          })
         }, (errVal) => {
           console.log(errVal);
           if (errVal.status === 401 || errVal.status === 403 || errVal.status === 0) {
            localStorage.clear();
            this.router.navigate([''])
            .then(() => {
              window.location.reload();
            });
          }
         });
    }
    });
   // setTimeout(() => {
      //this.getTransactionTypes();
   // }, 1000 );
  }

  //Method to request the new transaction
  transactionRequest() {
    this.submitted = true;
    this.errorMsg = '';
    if(this.submitted && this.transactionForm.invalid) {
      return;
    } else {
      if(this.f.capex.value == '' && this.isCapex) {
        return;
      } else if (this.f.opex.value == '' && this.isOpex) {
        return;
      }
      else if (this.f.OtherFinancial.value == '' && this.isOtherFinancial) {
        return;

      }
       else {
        if(this.id == ''){
          this.getTransactionID();
        }
        let params = {
          "id": this.id,
         // "ActivityID": this.category.id,
         "ActivityConfigTitle": this.category.Title,
          "ActivityConfigID": this.category.id,
          "Title": this.f.title.value.trim(),
          "Description": this.f.description.value.trim(),
          "TransactionType": this.category.Category,
          "FinanceAmount": this.f.capex.value!=''? parseInt(this.f.capex.value): this.f.opex.value!=''? parseInt(this.f.opex.value):parseInt(this.f.OtherFinancial.value),
          //"OPEXAmount": this.f.opex.value != '' ? parseInt(this.f.opex.value) : 0,
          "RequiredDocuments": this.transactionDocument,
          "RequestedDate": "",
          "RequestedUser": this.MsalauthService.instance.getAllAccounts()[0].username,
          "ApprovalLevels": this.category.ApprovalLevels,
          "CreatedUser": this.MsalauthService.instance.getAllAccounts()[0].username,
          "CreatedUserName": this.MsalauthService.instance.getAllAccounts()[0].name,
          "Category": this.category.Category,
          "BusinessUnit": this.category.BusinessUnit,
          "BusinessUnitGroupId": this.category.BusinessUnitGroupId,
          // "CategoryID": this.f.capex.value!=''? 1 : this.f.opex.value!=''? 2:4,
          "CategoryID": this.f.capex.value != '' ? 1 : this.f.opex.value != '' ? 2 :this.f.OtherFinancial.value != '' ? 4: 3,
          "ProposerGroups": this.category.ProposerGroups,
          "version": this.category.Version,


      };
      this.loader.show();
        this.restService.postData(params, '/api/Transaction/TransactionUpdate')
            .subscribe(
              (data) => {
                this.loader.hide();
                if (data.rcode == 1) {
                  //console.log(data)
                  this.router.navigate(['../transactions'])
                } else {
                  this.errorMsg = data.rmsg;
                }
          },
            (err) => {
              this.loader.hide();
              console.log(err);
              if (err.status === 401 || err.status === 403 || err.status === 0) {
                localStorage.clear();
                this.router.navigate([''])
                .then(() => {
                  window.location.reload();
                });
              }
            });
          }
    }
  }

  get f() {
    return this.transactionForm.controls;
  }

  // Method to check whether the input field pertains only for numbers (positive/negative) along with decimal validation
  //Sample input : <input type="text" name="poId" id="poId"  #testinput (keypress)="decimalOnly($event, testinput )">
  decimalOnly(event: any, element:any) {
    /* const pattern =/^-?(0|[1-9]\d*)(\.\d+)?(\+)?$/;
    let inputChar = String.fromCharCode(event.charCode);
    if ((event.keyCode != 8 && event.keyCode != 46 && event.keyCode != 45 && !pattern.test(inputChar)) || (event.keyCode == 46 && event.target.value.indexOf('.') != -1) || (event.keyCode == 45 && element.selectionStart != 0)) {
      event.preventDefault();
    } */

    const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
  }

    // Method to check whether the entered input is a valid number (i.e only numbers are allowed in the input field)
    numberOnly(event: any) {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }

  //Method to select file
  onSelectFile(event: any) {
    this.files.push(...event.addedFiles);
    const formData = new FormData();
    for (var i = 0; i < this.files.length; i++) { 
      formData.append("file[]", this.files[i]);
      this.uploadDocument(this.files[i]);

    }
    //this.uploadDocument();
  }

  //Method to remove selected file 
  onRemoveFile(doc: any) {
    this.files.splice(this.files.indexOf(doc), 1);
  }

  //Method to upload document
  uploadDocument(file:File): void {
    if(this.id != '') {
      this.loader.show();
      //console.log(this.files[this.files.length - 1]);
      const formData = new FormData();
      formData.append('document',file);
      formData.append('fileName',file.name);
      formData.append('TransactionID',this.id);
      this.restService.postData(formData, '/api/Transaction/AttachmentUpload', {reportProgress: true,  observe: 'events'})
      .subscribe(
        (data) => {
          this.loader.hide();
          if (data.rcode == 1) {
           //this.transactionDocument = [];
          // console.log(data.rObj);
           let doc = {
            "FileName": file.name,
            "FileURL": data.rObj.BlobURL
           }
           this.transactionDocument.push(doc);
           this.files = [];
        } if (data.rcode == 2) {
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '30vw',
            disableClose: true,
            data:{data: data.rmsg,  type:"WARNING"}
          });
          dialogRef.afterClosed().subscribe(result => {
            this.files = [];
          });
        }
        else {
          this.loader.hide();
          this.files = [];
        }
        this.loader.hide();
    },
      (err) => {
        this.loader.hide();
        console.log(err);
        if (err.status === 401 || err.status === 403 || err.status === 0) {
          localStorage.clear();
          this.router.navigate([''])
          .then(() => {
            window.location.reload();
          });
        }
      }); 
    } else {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '30vw',
        disableClose: true,
        data:{data:'Please select the workflow to upload the document',  type:"WARNING"}
      });
      dialogRef.afterClosed().subscribe(result => {
        this.files = [];
      });
    }
  }

  //Method to get Transaction ID
  getTransactionID() {
      let params = {
        "activityConfigID": this.category.id
      };
      this.restService.postData(params, '/api/Transaction/TransactionInsert')
      .subscribe(
        (data) => {
          if (data.rcode == 1) {
            //console.log(data)
            this.id = data.rObj.Transaction.id;
        }
    },
      (err) => {
        console.log(err);
        if (err.status === 401 || err.status === 403 || err.status === 0) {
          localStorage.clear();
          this.router.navigate([''])
          .then(() => {
            window.location.reload();
          });
        }
      });
  }

  //Method to get transaction types
  getTransactionTypes() {
    this.group = this.members.value;
    this.groupString = this.group.map(item => `'${item}'`).join(',');
    let params = {
      "GroupID": this.groupString
    };
    this.restService.postData(params, '/api/ActivityConfig/ActivityConfigFetchListAll')
    .subscribe(
      (data) => {
        if (data.rcode == 1) {
         // console.log(data);
         let _temp:any = [];
         data.rObj.ActivityConfig.forEach((item:any) => {
           if(item != null) {
             _temp.push(item)
           }
         })
          this.transactionTypes = _temp;
          if(this.transactionTypes.length!=0){
            this.selectCategory(this.data.ActivityConfigID)

          }
      }
  },
    (err) => {
      console.log(err);
      if (err.status === 401 || err.status === 403 || err.status === 0) {
        localStorage.clear();
        this.router.navigate([''])
        .then(() => {
          window.location.reload();
        });
      }
    });
  }

  //Method to set other parameters for API call
  selectCategory(type: any) {
    this.category = this.transactionTypes.find(item => item.id == type);
    if(this.category.Category=="CAPEX"){
      this.isCapex = true;
    } else {
      this.isCapex = false
    }
    if(this.category.Category=="OPEX"){
      this.isOpex = this.category.Category;
    } else {
      this.isOpex = false;
    }
     if(this.category.Category=="Other Financial"){
       this.isOtherFinancial = this.category.Category;
     } else {
   this.isOtherFinancial = false;
     }
    this.getTransactionID();
    this.transactionDocument = [];
   // console.log(JSON.stringify(this.category))
  }

  cancel() {
    this.transactionForm.reset();
    this.errorMsg = '';
    this.getTransactionTypes();
    this.files = [];
    this.transactionDocument = [];
    this.f.type.setValue('');
  }

  //Method to remove document attached
  removeDocument(doc: any) {
    var index = this.transactionDocument.indexOf(doc)
    this.transactionDocument.splice(index,1)
  }


  setValue(){
    this.f.type.setValue(this.data.ActivityConfigID);
    this.f.type.disable();
    this.f.title.setValue(this.data.Title);
    this.f.description.setValue(this.data.Description);
    if(this.data.Category == "CAPEX"){
      this.f.capex.setValue(this.data.FinanceAmount);
      // this.f.capex.disable();
    }
    if(this.data.Category == "OPEX"){
      this.f.opex.setValue(this.data.FinanceAmount);
      // this.f.opex.disable();
    }
    if(this.data.Category == "Other Financial"){
      this.f.OtherFinancial.setValue(this.data.FinanceAmount);
      // this.f.OtherFinancial.disable();
    }

   
   
    //this.f.category.setValue(this.data.FinanceAmount);
    

    for(let i=0;i<this.data.RequiredDocuments.length ;i++){
      // this.requiredDocument=(this.data.RequiredDocuments[i].FileName)
      // this.requiredUrl=(this.data.RequiredDocuments[i].FileURL)
      this.transactionDocument.push(this.data.RequiredDocuments[i]);
    }
this.estimatedAmount()
    //this.transactionDocument.push(doc);
    //this.transactionForm.setValue()
  }
  estimatedAmount() {
    this.lowestEstimatedAmt = Math.min.apply(Math, this.data.ApprovalLevels.map(function (o: { FinancialsLowerLimit: any; }) { return o.FinancialsLowerLimit; }))
    this.highestEstimatedAmt = Math.max.apply(Math, this.data.ApprovalLevels.map(function (o: { FinancialsUpperLimit: any; }) { return o.FinancialsUpperLimit; }))
    if (this.isCapex) {
      if (this.f.capex.value < this.lowestEstimatedAmt || this.f.capex.value > this.highestEstimatedAmt) {
        this.f.capex.setErrors({ capexEstimatedAmtErr: true })
      }
    
    }
    else if (this.isOpex) {
      if (this.f.opex.value < this.lowestEstimatedAmt || this.f.opex.value > this.highestEstimatedAmt) {
        this.f.opex.setErrors({ opexEstimatedAmtErr: true })
      }
  
    }
    else if (this.isOtherFinancial) {
      if (this.f.OtherFinancial.value < this.lowestEstimatedAmt || this.f.OtherFinancial.value > this.highestEstimatedAmt) {
        this.f.OtherFinancial.setErrors({ OtherFinancialEstimatedAmtErr: true })
      }
  
    }
  }

  updateSendBack(){
    this.submitted=true;
    //console.log(this.f.capex.value+' , '+  this.f.opex.value +' , '+ this.f.OtherFinancial.value);
    if (this.transactionForm.invalid) {
      return;
    }
    else{
      let params = {
        "id": this.data.id,
        "title": this.f.title.value,
        "description": this.f.description.value,
        "categoryID": this.category.CategoryID,
        "category": this.category.Category,
        "financeAmount": this.f.capex.value!=''? parseInt(this.f.capex.value): this.f.opex.value!=''? parseInt(this.f.opex.value):this.f.OtherFinancial.value!=''?parseInt(this.f.OtherFinancial.value): 0,
        "requiredDocuments": this.transactionDocument
        }
  
      this.loader.show();
      this.restService.postData(params, '/api/Transaction/TransactionUpdateForSendBack')
      .subscribe(
        (data) => {
          if (data.rcode == 1) {
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              width: '32vw',
              disableClose: true,
              data:{data: data.rmsg ,  type:"SUCCESS"} 
            });
            dialogRef.afterClosed().subscribe();
           this.closeDialog()
          
        }
  
        else{
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '32vw',
            disableClose: true,
            data:{data: data.rmsg ,  type:"WARNING"} 
          });
          dialogRef.afterClosed().subscribe();
         this.closeDialog()
        
        }
        this.loader.hide();
    }, )
    }
}
closeDialog() : void {
  this.dialogRef.close();
}

betweenSelectedPriceEnterd(){
  let actualPriceLevel=this.data.ApprovalLevels.filter((q:any) => {
    return q.FinancialsLowerLimit <= this.data.FinanceAmount && q.FinancialsUpperLimit >= this.data.FinanceAmount
  });

  if (this.isCapex) {
    let modifiedPriceLevel=this.data.ApprovalLevels.filter((q:any) => {
      return q.FinancialsLowerLimit <= this.f.capex.value && q.FinancialsUpperLimit >= this.f.capex.value
    });
    this.lowestEstimatedAmt =actualPriceLevel[0].FinancialsLowerLimit;
    this.highestEstimatedAmt=actualPriceLevel[0].FinancialsUpperLimit;
    if (!isEqual(actualPriceLevel,modifiedPriceLevel)) {
      this.f.capex.setErrors({ capexEstimatedAmtErr: true })
    }
  
  }
  else if (this.isOpex) {
    let modifiedPriceLevel=this.data.ApprovalLevels.filter((q:any) => {
      return q.FinancialsLowerLimit <= this.f.opex.value && q.FinancialsUpperLimit >= this.f.opex.value
    });
    this.lowestEstimatedAmt =actualPriceLevel[0].FinancialsLowerLimit;
    this.highestEstimatedAmt=actualPriceLevel[0].FinancialsUpperLimit;
    if (!isEqual(actualPriceLevel,modifiedPriceLevel)) {
      this.f.opex.setErrors({ opexEstimatedAmtErr: true })
    }

  }
  else if (this.isOtherFinancial) {
    let modifiedPriceLevel=this.data.ApprovalLevels.filter((q:any) => {
      return q.FinancialsLowerLimit <= this.f.OtherFinancial.value && q.FinancialsUpperLimit >= this.f.OtherFinancial.value
    });
    this.lowestEstimatedAmt =actualPriceLevel[0].FinancialsLowerLimit;
    this.highestEstimatedAmt=actualPriceLevel[0].FinancialsUpperLimit;
    if (!isEqual(actualPriceLevel,modifiedPriceLevel)) {
      this.f.OtherFinancial.setErrors({ OtherFinancialEstimatedAmtErr: true })
    }

  }

}


  }

