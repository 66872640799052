import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/core/auth.service';
import { TokenStorage } from 'src/app/core/token-storage';
import { LoaderService } from 'src/app/service/loader.service';
import { RestService } from 'src/app/service/rest.service';
import { AlertDialogComponent } from '../Shared/alert-dialog/alert-dialog.component';


@Component({
  selector: 'app-manage-transactions',
  templateUrl: './manage-transactions.component.html',
  styleUrls: ['./manage-transactions.component.css']
})
export class ManageTransactionsComponent implements OnInit {
  transactionForm!: FormGroup;
  submitted: boolean = false;
  isCapex: boolean = false;
  isOpex: boolean = false;
  isOtherFinancial: boolean = false
  transactionDocument: Array<any> = [];
  files: File[] = [];
  id: string = '';
  transactionTypes: Array<any> = [];
  category: any;
  errorMsg = '';
  group: Array<any> = [];
  groupString: string = '';
  members: any;
  lowestEstimatedAmt: number = 0;
  highestEstimatedAmt: number = 0;
  unSupportedFile:string='';
  constructor(
    private formBuilder: FormBuilder,
    private authservice: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private loader: LoaderService,
    public restService: RestService,
    private token: TokenStorage,
    private dialog: MatDialog,
    public MsalauthService: MsalService) { }

  ngOnInit(): void {
    this.transactionForm = this.formBuilder.group({
      type: ['', Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      capex: [''],
      opex: [''],
      OtherFinancial: [''],
      budgetedAmount: ['']
    });
    this.authservice.getMembers().subscribe(data => {
      this.members = data;
      this.getTransactionTypes();
    }, err => {
      if (err.status == 401) {
        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + this.token.MSALget()
        });
        this.restService.postData(null, '/api/Account/GetApplicationToken', headers).subscribe((data) => {
          this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
          this.authservice.getMembers().subscribe(dataVal => {
            this.members = dataVal;
            this.getTransactionTypes();
          })
        }, (errVal) => {
          console.log(errVal);
          if (errVal.status === 401 || errVal.status === 403 || errVal.status === 0) {
            localStorage.clear();
            this.router.navigate([''])
              .then(() => {
                window.location.reload();
              });
          }
        });
      }
    });
    // setTimeout(() => {
    //this.getTransactionTypes();
    // }, 1000 );
  }

  estimatedAmount() {
    this.lowestEstimatedAmt = Math.min.apply(Math, this.category.ApprovalLevels.map(function (o: { FinancialsLowerLimit: any; }) { return o.FinancialsLowerLimit; }))
    this.highestEstimatedAmt = Math.max.apply(Math, this.category.ApprovalLevels.map(function (o: { FinancialsUpperLimit: any; }) { return o.FinancialsUpperLimit; }))

    if (this.isCapex) {
      if (this.f.capex.value < this.lowestEstimatedAmt || this.f.capex.value > this.highestEstimatedAmt) {
        this.f.capex.setErrors({ capexEstimatedAmtErr: true })
      }
    
    }
    else if (this.isOpex) {
      if (this.f.opex.value < this.lowestEstimatedAmt || this.f.opex.value > this.highestEstimatedAmt) {
        this.f.opex.setErrors({ opexEstimatedAmtErr: true })
      }
  
    }
    else if (this.isOtherFinancial) {
      if (this.f.OtherFinancial.value < this.lowestEstimatedAmt || this.f.OtherFinancial.value > this.highestEstimatedAmt) {
        this.f.OtherFinancial.setErrors({ OtherFinancialEstimatedAmtErr: true })
      }
  
    }
  }


  //Method to request the new transaction
  transactionRequest() {
    this.submitted = true;
    this.errorMsg = '';

    if (this.submitted && this.transactionForm.invalid) {
      return;
     }
    //else if(this.unSupportedFile!=''){
    //   return;
    // }
     else {
      if (this.f.capex.value == '' && this.isCapex) {
        return;
      } else if (this.f.opex.value == '' && this.isOpex) {
        return;
      }
      else if (this.f.OtherFinancial.value == '' && this.isOtherFinancial) {
        return;

      }
      else {
        if (this.id == '') {
          this.getTransactionID();
        }
        let params = {
          "id": this.id,
          // "ActivityID": this.category.id,
          "ActivityConfigTitle": this.category.Title,
          "ActivityConfigID": this.category.id,
          "Title": this.f.title.value.trim(),
          "Description": this.f.description.value.trim(),
          "TransactionType": this.category.Category,
          "FinanceAmount": this.getValueForFinanceAmount(),
          // "FinanceAmount": this.f.capex.value != '' ? parseInt(this.f.capex.value) : this.f.opex.value != '' ? parseInt(this.f.opex.value) : this.f.OtherFinancial.value != '' ? parseInt(this.f.OtherFinancial.value) : 0,
          //"OPEXAmount": this.f.opex.value != '' ? parseInt(this.f.opex.value) : 0,
          "RequiredDocuments": this.transactionDocument,
          "RequestedDate": "",
          "RequestedUser": this.MsalauthService.instance.getAllAccounts()[0].username,
          "ApprovalLevels": this.category.ApprovalLevels,
          "CreatedUser": this.MsalauthService.instance.getAllAccounts()[0].username,
          "CreatedUserName": this.MsalauthService.instance.getAllAccounts()[0].name,
          "Category": this.category.Category,
          "BusinessUnit": this.category.BusinessUnit,
          "BusinessUnitGroupId": this.category.BusinessUnitGroupId,
          // "CategoryID": this.f.capex.value != '' ? 1 : this.f.opex.value != '' ? 2 : 4,
          // "CategoryID": this.f.capex.value != '' ? 1 : this.f.opex.value != '' ? 2 :this.f.OtherFinancial.value != '' ? 4: 3,
          "CategoryID": this.getValueForCategoryID(),
          "ProposerGroups": this.category.ProposerGroups,
          "version": this.category.Version,
          "BudgetedAmount":(this.isCapex || this.isOpex)? parseInt(this.f.budgetedAmount?.value ?? '0', 10):null
        };
        
        this.loader.show();
        this.restService.postData(params, '/api/Transaction/TransactionUpdate')
          .subscribe(
            (data) => {
              this.loader.hide();
              if (data.rcode == 1) {
                //console.log(data)
                this.router.navigate(['../transactions'])
              } else {
                this.errorMsg = data.rmsg;
              }
            },
            (err) => {
              this.loader.hide();
              console.log(err);
              if (err.status === 401 || err.status === 403 || err.status === 0) {
                localStorage.clear();
                this.router.navigate([''])
                  .then(() => {
                    window.location.reload();
                  });
              }
            });
      }
    }
  }

  getValueForCategoryID(): number {
    const categoryMappings: { [key: string]: number } = {
      isCapex: 1,
      isOpex: 2,
      isOtherFinancial: 4
    };
  
    for (const key in categoryMappings) {
      if ((this as any)[key]) {
        return categoryMappings[key];
      }
    }
  
    return 3;
  }
  
  

  // getValueForFinanceAmount(){
  //   if(this.isCapex){
  //     return this.f.capex.value != '' ? parseInt(this.f.capex.value):0;
  //   }else if(this.isOpex){
  //     return this.f.opex.value != '' ? parseInt(this.f.opex.value):0;
  //   }else if(this.isOtherFinancial){
  //     return this.f.OtherFinancial.value != '' ? parseInt(this.f.OtherFinancial.value):0;
  //   }else{
  //     return 0;
  //   }
  // }

  getValueForFinanceAmount(): number {
    const financeMappings:any = {
      capex: this.isCapex,
      opex: this.isOpex,
      OtherFinancial: this.isOtherFinancial
    };
  
    for (const key in financeMappings) {
      if (financeMappings[key]) {
        return parseInt(this.f[key]?.value ?? '0', 10);
      }
    }
    
    return 0;
  }
  
  get f() {
    return this.transactionForm.controls;
  }

  // Method to check whether the input field pertains only for numbers (positive/negative) along with decimal validation
  //Sample input : <input type="text" name="poId" id="poId"  #testinput (keypress)="decimalOnly($event, testinput )">
  decimalOnly(event: any, element: any) {
    /* const pattern =/^-?(0|[1-9]\d*)(\.\d+)?(\+)?$/;
    let inputChar = String.fromCharCode(event.charCode);
    if ((event.keyCode != 8 && event.keyCode != 46 && event.keyCode != 45 && !pattern.test(inputChar)) || (event.keyCode == 46 && event.target.value.indexOf('.') != -1) || (event.keyCode == 45 && element.selectionStart != 0)) {
      event.preventDefault();
    } */

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // Method to check whether the entered input is a valid number (i.e only numbers are allowed in the input field)
  numberOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  //Method to select file
  onSelectFile(event: any) {
    this.files.push(...event.addedFiles);
    const formData = new FormData();
    if(event.rejectedFiles.length==0){
      this.unSupportedFile=""

      for (var i = 0; i < this.files.length; i++) {
        formData.append("file[]", this.files[i]);
        this.uploadDocument(this.files[i]);
      }
     
    }
    else{
      let _invalidFiles=[]
      for (var i = 0; i < event.rejectedFiles.length; i++) {
        _invalidFiles.push(event.rejectedFiles[i].name)
      }
      this.unSupportedFile="These files - ("+ _invalidFiles.toString() +") are not supported"
    }
  
    //this.uploadDocument();
  }

  //Method to remove selected file 
  onRemoveFile(doc: any) {
    this.files.splice(this.files.indexOf(doc), 1);
  }

  //Method to upload document
  uploadDocument(file: File): void {
    if (this.id != '') {
      this.loader.show();
      //console.log(this.files[this.files.length - 1]);
      const formData = new FormData();
      formData.append('document', file);
      formData.append('fileName', file.name);
      formData.append('TransactionID', this.id);
      this.restService.postData(formData, '/api/Transaction/AttachmentUpload', { reportProgress: true, observe: 'events' })
        .subscribe(
          (data) => {
            this.loader.hide();
            if (data.rcode == 1) {
              //this.transactionDocument = [];
              // console.log(data.rObj);
              let doc = {
                "FileName": file.name,
                "FileURL": data.rObj.BlobURL
              }
              this.transactionDocument.push(doc);
              this.files = [];
            }if (data.rcode == 2) {
              const dialogRef = this.dialog.open(AlertDialogComponent, {
                width: '30vw',
                disableClose: true,
                data:{data: data.rmsg,  type:"WARNING"}
              });
              dialogRef.afterClosed().subscribe(result => {
                this.files = [];
              });
            }
            else {
              this.loader.hide();
              this.files = [];
            }
            this.loader.hide();
          },
          (err) => {
            this.loader.hide();
            console.log(err);
            if (err.status === 401 || err.status === 403 || err.status === 0) {
              localStorage.clear();
              this.router.navigate([''])
                .then(() => {
                  window.location.reload();
                });
            }
          });
    } else {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '30vw',
        disableClose: true,
        //data: 'Please select the workflow to upload the document'
        data:{data: 'Please select the workflow to upload the document' ,  type:"WARNING"}
      });
      dialogRef.afterClosed().subscribe(result => {
        this.files = [];
      });
    }
  }

  //Method to get Transaction ID
  getTransactionID() {
    let params = {
      "activityConfigID": this.category.id
    };
    this.restService.postData(params, '/api/Transaction/TransactionInsert')
      .subscribe(
        (data) => {
          if (data.rcode == 1) {
            //console.log(data)
            this.id = data.rObj.Transaction.id;
          }
        },
        (err) => {
          console.log(err);
          if (err.status === 401 || err.status === 403 || err.status === 0) {
            localStorage.clear();
            this.router.navigate([''])
              .then(() => {
                window.location.reload();
              });
          }
        });
  }

  //Method to get transaction types
  getTransactionTypes() {
    this.group = this.members.value;
    this.groupString = this.group.map(item => `'${item}'`).join(',');
    let params = {
      "GroupID": this.groupString
    };
    this.restService.postData(params, '/api/ActivityConfig/ActivityConfigFetchListAll')
      .subscribe(
        (data) => {
          if (data.rcode == 1) {
            // console.log(data);
            let _temp: any = [];
            data.rObj.ActivityConfig.forEach((item: any) => {
              if (item != null) {
                _temp.push(item)
              }
            })
            this.transactionTypes = _temp;
          }
        },
        (err) => {
          console.log(err);
          if (err.status === 401 || err.status === 403 || err.status === 0) {
            localStorage.clear();
            this.router.navigate([''])
              .then(() => {
                window.location.reload();
              });
          }
        });
  }

  //Method to set other parameters for API call
  selectCategory(type: any) {
    this.category = this.transactionTypes.find(item => item.id == type);
    if (this.category.Category == "CAPEX") {
      this.isCapex = true;
    } else {
      this.isCapex = false
    }
    if (this.category.Category == "OPEX") {
      this.isOpex = true;
    } else {
      this.isOpex = false;
    }
    if (this.category.Category == "Other Financial") {
      this.isOtherFinancial = true;
    } else {
      this.isOtherFinancial = false;
    }
    this.getTransactionID();
    this.transactionDocument = [];
    // console.log(JSON.stringify(this.category))
    this.f.capex.clearValidators();
    this.f.opex.clearValidators();
    this.f.OtherFinancial.clearValidators();

    this.f.capex.updateValueAndValidity();
    this.f.opex.updateValueAndValidity();
    this.f.OtherFinancial.updateValueAndValidity();
    if(this.isCapex || this.isOpex){
      this.f.budgetedAmount.setValidators([Validators.required]);
    }else{
      this.f.budgetedAmount.clearValidators();
    }
    this.f.budgetedAmount.updateValueAndValidity();
  }

  cancel() {
    this.transactionForm.reset();
    this.errorMsg = '';
    this.getTransactionTypes();
    this.files = [];
    this.transactionDocument = [];
    this.f.type.setValue('');
  }

  //Method to remove document attached
  removeDocument(doc: any) {
    var index = this.transactionDocument.indexOf(doc)
    this.transactionDocument.splice(index, 1)
  }

}
