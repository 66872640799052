import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/core/auth.service';
import { LoaderService } from 'src/app/service/loader.service';
import { RestService } from 'src/app/service/rest.service';
import { DateFilterPipe } from '../../pipes/dateFormat.pipe';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { ListOfDelegateUsersComponent } from '../list-of-delegate-users/list-of-delegate-users.component';

@Component({
  selector: 'app-awiting-approval-dialog',
  templateUrl: './awiting-approval-dialog.component.html',
  styleUrls: ['./awiting-approval-dialog.component.css']
})
export class AwitingApprovalDialogComponent implements OnInit {

  transactions: Array<any> = [];
  ApprovalForm!: FormGroup;
  submitted: boolean = false;
  transactionDocument: Array<any> = [];
  files: File[] = [];
  dataObj:any;
  typeId!:number;
  status!:string;
  sendBackTo:any[]=[];
  constructor(
    public dialogRef: MatDialogRef<AwitingApprovalDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog, 
    private fb: FormBuilder, 
    private loader: LoaderService, 
    public restService: RestService,
    private router: Router,
    public MsalauthService: MsalService,private datePipe: DateFilterPipe ) { 
      this.dataObj = this.data.dataObj;
      console.log(this.dataObj);
      
      this.typeId=this.data.type
     
    }

  ngOnInit(): void {
    this.ApprovalForm = this.fb.group({
      comments: ['', Validators.required],
      untillDate:[this.typeId==1004?new Date():null]
    });
    if(this.typeId==1002)this.status='Approved';
    if(this.typeId==1003)this.status='Rejected';
    if(this.typeId==1004)this.status='On Hold';
    if(this.typeId==1005)this.status='Sent Back To Previous Level';
    if(this.typeId==1008)this.status='Sent BackTo Initiator';
    
    console.log(this.dataObj);
    if([1005,1008,1011].includes(this.typeId)){
      this.ApprovalForm.addControl('sendBackTo', new FormControl('', Validators.required));
      this.sendBackTo= this.getPreviousLevels();
      this.ApprovalForm.get('sendBackTo')?.valueChanges.subscribe(value=>{
        switch(parseInt(value)){
          case 1005:
            this.status='Sent Back To Previous Level';
            this.typeId=1005;
            break;
          case 1008:
            this.status='Sent Back To Initiator';
            this.typeId=1008;
            break;
          default:
            this.status=`Sent Back To Level ${value}`;
            this.typeId=1011;
        }
        console.log(this.status);
        
      })
    }
  }

  getPreviousLevels(){
    let previousApprovalLevelList:any=[];
    let previousLevels:any[]=[];
    // if(this.dataObj.CategoryID!=3){
      let allLevels=this.dataObj.ApprovalLevels.filter((q:any) => q.FinancialsLowerLimit <= this.dataObj.FinanceAmount && q.FinancialsUpperLimit >= this.dataObj.FinanceAmount && q.Level != this.dataObj.CurrentLevel);
      let distinctLevels = allLevels.reduce((uniqueLevels:any, currentLevel:any) => {
        const existingLevel = uniqueLevels.find((unique:any) => unique.Level === currentLevel.Level);
        if (!existingLevel) {
          uniqueLevels.push(currentLevel);
        }
        return uniqueLevels;
      }, []);

      if(distinctLevels.length>0){
         previousLevels = distinctLevels.filter((q:any) => q.Level < this.dataObj.CurrentLevel);
      }else{
        allLevels = this.dataObj.ApprovalLevels.filter((q:any) => q.Level <= this.dataObj.CurrentLevel - 1);
         distinctLevels = allLevels.reduce((uniqueLevels:any, currentLevel:any) => {
          const existingLevel = uniqueLevels.find((unique:any) => unique.Level === currentLevel.Level);
          if (!existingLevel) {
            uniqueLevels.push(currentLevel);
          }
          return uniqueLevels;
        }, []);
        previousLevels= distinctLevels;
      }
      let sentBackToInitiator={label:'Sent Back To Initiator',value:1008};
      let sentBackToPreviousLevel={label:'Sent Back To Previous Level',value:1005};
      if(previousLevels.length > 0){
        previousLevels = previousLevels.filter((q:any) => q.Level <= this.dataObj.CurrentLevel - 2);
        let previousLevelsModeifiedData = previousLevels.map(({ Level }) => ({
          label: `Sent Back To Level ${Level}`,
          value: Level
        }));
        previousApprovalLevelList.push(sentBackToInitiator);
        previousApprovalLevelList.push(sentBackToPreviousLevel);
        previousApprovalLevelList = [...previousApprovalLevelList, ...previousLevelsModeifiedData];
        this.ApprovalForm.get('sendBackTo')?.patchValue(1005);
        this.status='Sent Back To Previous Level';
        this.typeId=1005;
      }else{
        previousApprovalLevelList.push(sentBackToInitiator);
        this.ApprovalForm.get('sendBackTo')?.patchValue(1008);
        this.status='Sent Back To Initiator';
        this.typeId=1008;
      }
    // }
    return previousApprovalLevelList;
  }
  get f() {
    return this.ApprovalForm.controls;
  }
  closeDialog() : void {
    this.dialogRef.close();
  }

 //Method to select file
 onSelectFile(event: any) {
  this.files.push(...event.addedFiles);
  const formData = new FormData();
  for (var i = 0; i < this.files.length; i++) { 
    formData.append("file[]", this.files[i]);
    this.uploadDocument(this.files[i]);
  }
}

//Method to remove selected file 
onRemoveFile(doc: any) {
  this.files.splice(this.files.indexOf(doc), 1);
}

//Method to upload document
uploadDocument(file:File): void {
  this.loader.show();
    const formData = new FormData();
    formData.append('document',file);
    formData.append('fileName',file.name);
    formData.append('TransactionID',this.data.id);
    this.restService.postData(formData, '/api/Transaction/AttachmentUpload', {reportProgress: true,  observe: 'events'})
    .subscribe(
      (data) => {
        this.loader.hide();
        if (data.rcode == 1) {
         let doc = {
          "FileName": file.name,
          "FileURL": data.rObj.BlobURL
         }
         this.transactionDocument.push(doc);
         this.files = [];
      } if (data.rcode == 2) {
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '30vw',
          disableClose: true,
          data:{data: data.rmsg,  type:"WARNING"}
        });
        dialogRef.afterClosed().subscribe(result => {
          this.files = [];
        });
      }
      else {
        this.loader.hide();
        this.files = [];
      }
      this.loader.hide();
  },
    (err) => {
      this.loader.hide();
      console.log(err);
    }); 
  }
  submitApproveAwait(data?:any){
    let params:any;
    this.submitted = true;
    if(this.ApprovalForm.invalid) {
      return;
    } else {
      this.loader.show();
      if(data){
        params=data;
      }else{
        params = {
          "TransactionID":this.dataObj.id,
          "Level": this.dataObj.CurrentLevel,
          "ApprovedUser":this.MsalauthService.instance.getAllAccounts()[0].username?.toString(),
          "ApprovedUserName": this.MsalauthService.instance.getAllAccounts()[0].name?.toString(),
          "Document":this.transactionDocument,
          "Comments": this.f.comments.value.trim(),
          "Status":this.status,
          "StatusID":this.typeId,
          "Until":this.f.untillDate.value ?this.datePipe.transform(this.f.untillDate.value, 'MM-dd-yyyy'):null,
          "delegateUser":"",
          "isApprovedAsDelegateUser":false,
          "SendBackLevel":[1005,1008].includes(this.typeId)?0:
          this.ApprovalForm.contains('sendBackTo')?
          parseInt(this.ApprovalForm.get('sendBackTo')?.value):0
        }
      }
      console.log(params);
      
      this.restService.postData(params, '/api/Transaction/TransactionApproval')
        .subscribe(data => {
          this.loader.hide();
          if (data.rcode == 4){
            const dialogRef = this.dialog.open(ListOfDelegateUsersComponent, {
              width: '65vw',
              disableClose: true,
              panelClass: 'delegate-users-dialog-class',
              data:{data: data.rObj.DelegateUsers,  type:"Delegate Users"} 
            });
            dialogRef.afterClosed().subscribe(result=>{
              this.dialogRef.close();
              if(result){
               let TransactionApprovalParams = {
                  "TransactionID":this.dataObj.id,
                  "Level": this.dataObj.CurrentLevel,
                  "ApprovedUser":this.MsalauthService.instance.getAllAccounts()[0].username?.toString(),
                  "ApprovedUserName": this.MsalauthService.instance.getAllAccounts()[0].name?.toString(),
                  "Document":this.transactionDocument,
                  "Comments": this.f.comments.value.trim(),
                  "Status":this.status,
                  "StatusID":this.typeId,
                  "Until":this.f.untillDate.value ?this.datePipe.transform(this.f.untillDate.value, 'MM-dd-yyyy'):null,
                  "delegateUser":result.delegateUser,
                  "isApprovedAsDelegateUser":result.isApprovedAsDelegateUser,
                  "SendBackLevel":[1005,1008].includes(this.typeId)?0:
                  this.ApprovalForm.contains('sendBackTo')?
                  parseInt(this.ApprovalForm.get('sendBackTo')?.value):0
                }
                this.submitApproveAwait(TransactionApprovalParams);
                this.router.navigate(["my-approvals"])
              }
            });
          }
          else if (data.rcode == 1) {
              const dialogRef = this.dialog.open(AlertDialogComponent, {
                width: '32vw',
                //height: '25vh',
                
                disableClose: true,
                data:{data: data.rmsg ,  type:"SUCCESS"} 
              });
              dialogRef.afterClosed().subscribe(result=>{
                if(result){
                  this.dialogRef.close();
                  this.router.navigate(["my-approvals"])
                }
              });
            //this.dialogRef.close();
          } else {
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              width: '32vw',
              //height: '25vh',
              
              disableClose: true,
              data:{data: data.rmsg ,  type:"WARNING"} 
            });
            dialogRef.afterClosed().subscribe(result=>{
              if(result){
                this.dialogRef.close();
                this.router.navigate(["my-approvals"])
              }
            });

          }
        },
          (err) => {
            this.loader.hide();
            console.log(err);
            if (err.status === 401 || err.status === 403 || err.status === 0) {
              localStorage.clear();
              this.router.navigate([''])
              .then(() => {
                window.location.reload();
              });
            }
          });
    }
   

  }

    //Method to remove document attached
    removeDocument(doc: any) {
      var index = this.transactionDocument.indexOf(doc)
      this.transactionDocument.splice(index,1)
    }


}
