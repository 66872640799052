import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.css']
})
export class DocViewerComponent implements OnInit {
  fileURL: any = '';
  fileName: any = '';
  fileExtension: any = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {
    this.route.paramMap.subscribe(params => {
      this.fileURL = params.get('url');
      this.fileExtension = this.getFileExtension(this.fileURL);
    });
    this.route.queryParams.subscribe(params => {
      this.fileName = params['fileName'];
    });
  }

  ngOnInit(): void {
  }

  getFileExtension(url: any) {
    return url.split('.').pop();
  }
  downloadFile(){
    this.http.get(this.fileURL, { responseType: 'blob' }).subscribe(blob => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = this.fileName; 
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
}
